import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'

import infinitySvg from './infinity-symbol.svg'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import '../../assets/css/myAccount.css';

const Addons = (props) => { 

    const [cloudId, setCloudId] = useState("FONUSMOBILE");
    const[loading, setLoading] = useState(false);
    const [cloudUsername, setCloudUsername] = useState("");
    const [cloudPassword, setCloudPassword] = useState("");
    const [redirect, setRedirect] = useState(null);
    const [showRates, setShowRates] = useState(false);
    const [prefixRates, setPrefixRates] = useState([]);
    const [countries, setCountries] = useState([]);


    useEffect(() => {  
        const encodedUrlString = window.location.href.replace(/\+/g, '%2B');
        const url = new URL(encodedUrlString);
        const username  = url.searchParams.get("cloud_username");
        const password = url.searchParams.get("cloud_password");
        setCloudUsername(username);
        setCloudPassword(password);
        getRates();
        //fetchCountries();
    }, []); 

    const fetchCountries = async () => {
        try {
            const response = await fetch('https://restcountries.com/v3.1/all');
            const data = await response.json();
            setCountries(data);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const findCountry = (code) => {
        const country = countries.find(country => country.cca2 === code);
        if (country) {
            return country.name.common;
        } else {
            return code;
        }
    }

    const getRates = () => {

        const payLoad = {
            group_id : "1"
        }
        axios.post(`${process.env.REACT_APP_API_URL}billing/prefixRatesSumamry`, payLoad)
        .then(response => {
            // sort by rate
            const sortedData = response.data.sort((a, b) => {
                if (a.sms_rate_per_unit === "0") {
                    return -1;
                } else if (b.sms_rate_per_unit === "0") {
                    return 1;
                } else {
                    return parseFloat(a.sms_rate_per_unit) - parseFloat(b.sms_rate_per_unit);
                }
            });
            setPrefixRates(sortedData)
        }).catch(err => {
            console.log(err);
        });
    }
    
    const topUpuser = () => {

        confirmAlert({
            title: 'Are you sure?',
            message: 'This will automatically deduct US$5.00 through the default payment method on your account.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoading(true);
                        const payLoad = {
                            cloud_username: cloudUsername,
                            cloud_password: cloudPassword,
                            cloud_id: cloudId,
                            package_name: "Global Texting Package",
                            payment_method: "stripe",
                            currency: "usd",
                            price: 5
                        };
                        
                        axios.post(`${process.env.REACT_APP_API_URL}billing/fonusTopUp`, payLoad)
                        .then(response => {


                            setLoading(false);
                            Toast.success("Package Subscribed Successfully");
                        }).catch(err => {
                            Toast.error("Failed to subscribe Add-On. Please contact support.")
                            setLoading(false);
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
            
        });
        
    }



    

    return (
        <Auxi>
            {redirect}
            <div className = "my-addon-page">
                <div className="row outer-container">
                    <h3 className="m-b-10 loader-center">
                        {loading
                            ? <Spinner data="" />
                            : null
                        }
                    </h3>
                    <div className="col-md-12 number-selection-heading">
                        <i className="feather icon-chevron-left" onClick={e => setRedirect(<Redirect to = {"/my_account?cloud_username="+cloudUsername+"&cloud_password="+cloudPassword} />)}> </i>
                        <h1> Add-Ons </h1>
                    </div>

                    <div className="col-md-12" style={{marginTop:"10px"}}>
                        <div className="add-on-container">
                            <div className="text-container">
                                <h4 onClick={topUpuser}>Global Texting Package</h4>
                                <p onClick={topUpuser}>
                                    Send texts to over 150 countries.<br/>
                                    Charged on a per-SMS basis<br/>
                                    starting at $0.02 per text. This<br/>
                                    package provides a $5 credit.<br/>
                                    For rates, click 
                                </p>
                                <span style={{position:"absolute", left:"156px", bottom:"40px", cursor:"pointer", color:"#007bff"}}
                                    onClick={()=> setShowRates(true)}>here</span>
                            </div>
                            
                            <div className="price" onClick={topUpuser}>
                                <h2>$5</h2>
                            </div>
                        </div>
                        
                    </div>

                    {
                        showRates?
                            <div className='contry-rates-container'>
                                <span className="close-icon"
                                    onClick={e=> setShowRates(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        >
                                        <mask
                                            id="mask0_3096_8388"
                                            maskUnits="userSpaceOnUse"
                                            x="0"
                                            y="0"
                                            width="18"
                                            height="18"
                                        >
                                            <rect width="18" height="18" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_3096_8388)">
                                            <path
                                                d="M9.00036 9.79072L5.19553 13.5955C5.09169 13.6994 4.96117 13.7525 4.80396 13.7549C4.64676 13.7573 4.51383 13.7042 4.40518 13.5955C4.29652 13.4869 4.24219 13.3552 4.24219 13.2004C4.24219 13.0456 4.29652 12.9138 4.40518 12.8052L8.20999 9.00036L4.40518 5.19553C4.30133 5.09169 4.24821 4.96117 4.24581 4.80396C4.24339 4.64676 4.29652 4.51383 4.40518 4.40518C4.51383 4.29652 4.64556 4.24219 4.80036 4.24219C4.95516 4.24219 5.08688 4.29652 5.19553 4.40518L9.00036 8.20999L12.8052 4.40518C12.909 4.30133 13.0395 4.24821 13.1968 4.24581C13.354 4.24339 13.4869 4.29652 13.5955 4.40518C13.7042 4.51383 13.7585 4.64556 13.7585 4.80036C13.7585 4.95516 13.7042 5.08688 13.5955 5.19553L9.79072 9.00036L13.5955 12.8052C13.6994 12.909 13.7525 13.0395 13.7549 13.1968C13.7573 13.354 13.7042 13.4869 13.5955 13.5955C13.4869 13.7042 13.3552 13.7585 13.2004 13.7585C13.0456 13.7585 12.9138 13.7042 12.8052 13.5955L9.00036 9.79072Z"
                                                fill="#01080F"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Country</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            prefixRates.map((prefixRate, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{prefixRate.country_name}</td>
                                                        <td>
                                                            {
                                                                prefixRate.sms_rate_per_unit === "0"?
                                                                    'unlimited'
                                                                : `$${prefixRate.sms_rate_per_unit}`
                                                            }
                                                            {}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        : null
                    }
                </div>

            </div>

        </Auxi>

    );


}

export default Addons;
